import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  dateCreated: string
  image?: ImageProps
  label?: string
  languageCode: string
  title: string
  URL: string
}

export const Offer = memo(function Offer({
  className,
  image,
  label,
  languageCode,
  title,
  URL,
}: Props) {
  return (
    <Container className={className} to={URL}>
      {image ? <LazyLoadImage {...image} /> : null}
      {label ? <Label className="offer-label">{label}</Label> : null}
      <Wrapper className="offer-wrap" dial={5}>
        <Title>{title}</Title>
        <CTA
          className="offer-cta"
          label={useVocabularyData('discover-details', languageCode)}
          noActions
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  height: 25.417vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  &:hover {
    &:before {
      opacity: 0.6;
    }
    img {
      transform: scale(1.1);
    }
    .offer-wrap {
      &:before {
        opacity: 1;
      }
    }
    .offer-label {
      opacity: 0;
    }
    .offer-cta {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    padding-bottom: 87%;
  }
`

const Label = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 700;
  letter-spacing: 0.055rem;
  line-height: 0.875rem;
  padding: 0.6875rem 1.0625rem;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  transition: 0.2s ease-in-out;
`

const Wrapper = styled(FlexBox)`
  padding: 2.5625rem;
  position: absolute;
  top: 2.25rem;
  right: 2.25rem;
  bottom: 2.25rem;
  left: 2.25rem;
  text-align: center;
  z-index: 3;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    backface-visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
  position: relative;
  z-index: 2;
`

const CTA = styled(Button)`
  display: block;
  background: none;
  position: absolute;
  opacity: 0;
  bottom: 2.625rem;
  right: 0;
  left: 0;
  transform: translateY(1.5rem);
  transition: 0.2s ease-in-out;
  &:hover {
    background: none;
  }

  @media (max-width: 1439px) {
    display: none;
  }
`
