import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  buttonURL?: string
  description?: string
  languageCode: string
  languagePrefix?: string
  offers: OfferProps[]
  title?: string
  variant?: Variant
}

export const OffersSlider = memo(function OffersSlider({
  buttonURL,
  description,
  languageCode,
  languagePrefix,
  offers,
  title,
  variant = '3items',
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    autoAdjustSlidesPerView: false,
    loop: offers.length > 2 ? true : false,
    slidesPerView: variant === '2items' ? 2 : 3,
    spacing: 4,
    breakpoints: {
      '(max-width: 1023px)': {
        centered: true,
        loop: offers.length > 1 ? true : false,
        slidesPerView: 1.15,
        spacing: 3,
      },
    },
  })

  return (
    <Container variant={variant}>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      <Wrapper>
        <FadeInUp>
          <Slider
            className="keen-slider"
            data-keen-slider-pe="true"
            ref={sliderRef}
          >
            {offers
              .sort(
                (a, b) =>
                  new Date(b.dateCreated).getTime() -
                  new Date(a.dateCreated).getTime(),
              )
              .map((item, index) => (
                <Offer className="keen-slider__slide" key={index} {...item} />
              ))}
          </Slider>
          <Arrows row space="between">
            <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || slider.next()}
            />
          </Arrows>
        </FadeInUp>
      </Wrapper>
      {buttonURL ? (
        <FadeInUp>
          <CTA
            label={useVocabularyData('all-offers', languageCode)}
            URL={
              languagePrefix
                ? `/${languagePrefix}/${buttonURL}`
                : `/${buttonURL}`
            }
            variant="S"
          />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  margin: 11.875rem auto 7.5rem;
  padding: 3.4375rem 8.958vw 4.6875rem;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: calc(100% - 23.333vw);
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 11.667vw;
  }

  ${({ variant }) => {
    switch (variant) {
      case '2items':
        return css`
          margin: 7.5rem auto;
          padding-right: 11.667vw;
          padding-left: 11.667vw;
          &:before {
            width: calc(100% - 36.389vw);
            left: 18.194vw;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    margin: 7.5rem auto 5.625rem;
    padding: 3.75rem 0;
    &:before {
      width: 100%;
      left: 0;
    }
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 1.75rem;
  position: relative;
  z-index: 3;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1023px) {
    margin-top: 0.75rem;
  }
`

const CTA = styled(Button)`
  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }
`

const Wrapper = styled.div`
  margin-top: 3.125rem;
  position: relative;
`

const Slider = styled.div`
  position: relative;
  z-index: 2;
`

const Arrows = styled(FlexBox)`
  width: calc(100% + 9.875rem);
  position: absolute;
  top: 50%;
  left: -4.9375rem;
  transform: translateY(-50%);

  > div {
    background: none;
    &:hover {
      background: none;
      svg {
        stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
      }
    }

    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight1};
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = '3items' | '2items'
